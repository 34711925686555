input,button{
  padding: 0.5rem;
  user-select: none;
  border-radius: var(--border-radius);
  font-family: var(--font-family-text);
  transition: all 500ms ease;
}
input {
  background: var(--color-secondary);
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
}
button {
  background: var(--color-primary);
  border: 1px solid var(--color-primary);
  color: var(--color-secondary);
}

input:focus {
  outline: none;
  background: var(--color-secondary);
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  transition: all 500ms ease;
}

button:hover,
button:focus {
  cursor: pointer;
  background: var(--color-secondary);
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  transition: all 500ms ease;
}
